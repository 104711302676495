/* eslint-disable */
import { WINDOW } from '@ng-web-apis/common';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TRACKING_PROVIDER } from 'asap-team/asap-tools';

// Consts
import { DEFAULT_EVENT_CATEGORY } from '@consts/analytics-events';

type TrackingProvider = {
  id: string;
  name: string;
};

@Injectable({ providedIn: 'root' })
export class TrackingScriptsService {

  private DEFAULT_FB_ID: string = '358339994660819';

  private DEFAULT_GA_ID: string = 'UA-121778024-3';

  private ready: BehaviorSubject<boolean> = new BehaviorSubject(null);

  ready$: Observable<boolean> = this.ready.asObservable().pipe(
    filter<boolean>(Boolean),
  );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) {}

  private customEvents = {};

  private setup({ name, id }: TrackingProvider): void {
    const window = this.window as any;
    const document = this.document as any;

    switch (name) {
      // @ts-ignore
      case 'GTA': {
        if (id) {
          gtag('config', id)
        }
      }
      case TRACKING_PROVIDER.FB: {
        (function (f, b, e, v, n, t, s): void {
          if (f.fbq) { return; }

          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };

          if (!f._fbq) { f._fbq = n; }

          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));

        window.fbq('init', this.DEFAULT_FB_ID);

        if (!id) {
          window.fbq('track', 'PageView');

          return;
        }

        window.fbq('init', id);
        window.fbq('track', 'PageView');

        return;
      }
      case TRACKING_PROVIDER.GA: {
        if (id) {
          gtag('config', id);
        }
        break;
      }
      case TRACKING_PROVIDER.GTM: {
        if (id) {
          gtag('config', id);
        }
        break;
      }
      default: { break; }
    }
  }

  init(params: TrackingProvider[], customEvents?: any): void {
    if (customEvents) {
      this.customEvents = customEvents;
    }

    params.map((item: TrackingProvider) => {
      this.setup(item);
    });

    this.ready.next(true);
  }

  send(eventAction: string, eventCategory?: string): void {
    const window = this.window as any;

    this.ready$
      .pipe(
        take(1),
      )
      .subscribe(() => {
        if (window.dataLayer) {
          if (this.customEvents[eventAction]) {
            window.dataLayer.push([
              'event',
              this.customEvents[eventAction].eventName,
              this.customEvents[eventAction].eventValue,
            ]);
          } else {
            gtag('event', eventAction, { event_category: eventCategory || DEFAULT_EVENT_CATEGORY, event_label: '' });
          }
        }
      });
  }
}
